import 'core-js/stable';
import objectFitImages from 'object-fit-images';

window.usingPolyfill = true;
window.objectFitImages = objectFitImages;
console.log('Polyfills ready');

objectFitImages('img');

document.addEventListener('DOMContentLoaded', function() {
    objectFitImages('img');
});